

























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.el-dialog__body{
    padding: 10px;
}
.el-dialog__header{
    padding: 0px;
}
.el-dialog__footer{
    padding: 5px;
    border-top:1px solid #ccc;
} 
.el-dialog__headerbtn{font-size: 16px; background:white;top:5px !important;}
//.el-dialog__headerbtn i { background:white;font-size: 20px;  }

.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after{
    border-color :#3d77eb;
}
.el-checkbox__input.is-disabled+span.el-checkbox__label{
    color:#3d77eb
}

